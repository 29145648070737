import React from 'react';

import { MonitoringPlatform } from 'onescreen/models';
import { selectMonitoringPlatform } from 'onescreen/store/slices/data';
import { useSelector } from 'react-redux';
import { Typography } from 'onescreen/components';
import { DateMillisTuple } from 'onescreen/types';
import { getSolarPerformanceForDateRange, getStoragePerformanceForDateRange } from './util';

/*==================================Types====================================*/
type PerformanceBarProps = {
  monitoringPlatformId: MonitoringPlatform['id'];
  dateSpan: DateMillisTuple;
  clickBar: (id?: MonitoringPlatform['id']) => void;
};

/*================================Components=================================*/

export const SolarPerformanceRatioBar: React.FC<PerformanceBarProps> = ({
  monitoringPlatformId,
  dateSpan,
  clickBar,
}) => {
  const mp = MonitoringPlatform.fromObject(
    useSelector(selectMonitoringPlatform(monitoringPlatformId))
  );
  const dateRangePerf = getSolarPerformanceForDateRange(mp, dateSpan);
  const value = Math.round(dateRangePerf * 1000) / 1000;
  let color = 'green';
  if (value < 0.8 && value > 0.5) {
    color = 'yellow darken-2';
  } else if (value <= 0.5) {
    color = 'red';
  }

  return (
    <div
      className={'solar-performance-bar progress grey lighten-2 ' + (value <= 2 ? 'center' : '')}
      style={{ height: '30px', margin: 0, padding: 0 }}
      onClick={() => clickBar(mp?.id)}
    >
      <div className={'determinate ' + color} style={{ width: `${value * 100}%` }}>
        <Typography style={{ padding: '5px' }}>{Math.round(value * 10000) / 100}%</Typography>
      </div>
    </div>
  );
};

export const BatteryPerformanceRatioBar: React.FC<PerformanceBarProps> = ({
  monitoringPlatformId,
  dateSpan,
  clickBar,
}) => {
  const mp = MonitoringPlatform.fromObject(
    useSelector(selectMonitoringPlatform(monitoringPlatformId))
  );
  const dateRangePerf = getStoragePerformanceForDateRange(mp, dateSpan);
  const value = Math.round(dateRangePerf * 1000) / 1000;
  let color = 'green';
  if (value < 0.2 && value > 0) {
    color = 'yellow darken-2';
  } else if (value <= 0) {
    color = 'red';
  }

  return (
    <div
      className={'solar-performance-bar progress grey lighten-2 ' + (value <= 2 ? 'center' : '')}
      style={{ height: '30px', margin: 0, padding: 0 }}
      onClick={() => clickBar(mp?.id)}
    >
      {value ? (
        <div className={'determinate ' + color} style={{ width: `${value * 50}%` }}>
          <Typography style={{ padding: '5px' }}>{Math.round(value * 10000) / 100}%</Typography>
        </div>
      ) : (
        <Typography style={{ padding: '5px' }}>No Data</Typography>
      )}
    </div>
  );
};

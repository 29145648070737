import _ from 'lodash';

import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Chip, ColumnConfig, Loading, SortingTable } from 'onescreen/components';

import { SolarPerformanceRatioBar, BatteryPerformanceRatioBar } from './PerformanceRatioBar';
import { SolarPerformanceGraph, StoragePerformanceGraph } from './PerformanceGraph';
import { KnownIssueForm, KnownIssueButton } from './KnownIssueForm';
import { useMonitoringPlatforms } from 'onescreen/hooks/monitoring';
import { MonitoringPlatform } from 'onescreen/models';
import { useDialogState } from 'onescreen/hooks';
import { Maybe } from 'onescreen/types';
import { DateSpanPicker } from 'onescreen/components';
import { DateMillisTuple } from 'onescreen/types';
import { DateTime } from 'luxon';
import {
  getSolarPerformanceForDateRange,
  getStoragePerformanceForDateRange,
  getBaselineForDateRange,
  defaultStartDate,
  defaultEndDate,
} from './util';

/*============================== Types ======================================*/

type DynamicYesterdayTableProps = {};

/*================================ Functions ================================*/
let getCommonColumns = (
  deviceTitle: 'Storage' | 'SolarGenerator'
): ColumnConfig<MonitoringPlatform>[] => [
  {
    header: 'Organization',
    key: 'org',
    value: function (mp: MonitoringPlatform) {
      const org = mp[deviceTitle]?.ServiceDrop?.Site?.Org;
      if (!org) return '';
      if (org) return org.name;
    },
    render: function (mp: MonitoringPlatform) {
      const org = mp[deviceTitle]?.ServiceDrop?.Site?.Org;
      if (!org) return '';
      return (
        <Link className="black-text" to={`/orgs/${org.id}`}>
          {org.name}
        </Link>
      );
    },
    noFilter: false,
  },
  {
    header: 'Service Drop',
    key: 'service_drop',
    value: function (mp: MonitoringPlatform) {
      const serviceDrop = mp[deviceTitle]?.ServiceDrop;
      const site = serviceDrop?.Site;
      return `${site?.name} ${serviceDrop?.name}`;
    },
    render: function (mp: MonitoringPlatform) {
      const serviceDrop = mp[deviceTitle]?.ServiceDrop;
      const site = serviceDrop?.Site;
      if (site && serviceDrop) {
        return (
          <Link
            className="black-text"
            to={`/orgs/${site.org}/site/${site.id}/service-drop/${serviceDrop.id}`}
          >
            {site.name} {serviceDrop.name}
          </Link>
        );
      }
    },
  },
  {
    header: 'Account Owner',
    key: 'account_owner',
    value: function (mp: MonitoringPlatform) {
      const owner = mp[deviceTitle]?.ServiceDrop?.Site?.Org?.AccountOwner;
      return owner ? `${owner.first_name} ${owner.last_name}` : '';
    },
    render: function (mp: MonitoringPlatform) {
      const owner = mp[deviceTitle]?.ServiceDrop?.Site?.Org?.AccountOwner;
      return owner ? `${owner.first_name} ${owner.last_name}` : '';
    },
  },
  {
    header: 'Monitoring Platform',
    key: 'monitor_name',
    render: (mp: MonitoringPlatform) => (
      <a target="_blank" rel="noopener noreferrer" href={mp.access_link}>
        {mp.monitor_name}
      </a>
    ),
  },
];

/*================================ Components ===============================*/

export const DynamicSolarYesterdayTable: React.FC<DynamicYesterdayTableProps> = () => {
  let [focusMp, setFocusMp] = useState<Maybe<MonitoringPlatform>>();
  const [start, end] = [defaultStartDate(), defaultEndDate()];
  const [dateSpan, setDateSpan] = useState<DateMillisTuple>([start.toMillis(), end.toMillis()]);
  const datesDidChange = useCallback((start_date: DateTime, end_date: DateTime) => {
    setDateSpan([start_date.toMillis(), end_date.toMillis()]);
  }, []);

  const { monitoring_platforms, loading } = useMonitoringPlatforms({
    include: [
      'solar_generator.service_drop.site.org.*',
      'solar_generator.service_drop.site.org.account_owner.*',
      'yesterday',
      'past_year_performance',
    ],
    filter: {
      // filter out hidden and disabled solar monitors.
      'hidden': { operation: 'equals', value: false },
      'disabled': { operation: 'equals', value: false },
      'solar_generator.service_drop.site.org.is_ams_customer': { operation: 'equals', value: true },
    },
    limit: 100,
    offset: 0,
  });

  const monitorIds =
    monitoring_platforms &&
    _.filter(Object.values(monitoring_platforms.results), { storage: null }).map(
      (mp) => (mp as MonitoringPlatform)?.id
    );
  const monitors = _.truthy(monitorIds?.map(MonitoringPlatform.fromStore) || []);

  const [performanceDialogOpen, performanceOpenDialog, performanceCloseDialog] = useDialogState();
  const [issueDialogOpen, issueOpenDialog, issueCloseDialog] = useDialogState();

  if (loading && !monitors.length) return <Loading />;

  let columns = getCommonColumns('SolarGenerator');
  //  BASELINES (SOLAR ONLY)
  columns.push({
    header: 'Baseline kWh/Day',
    key: 'baseline',
    value: (mp: MonitoringPlatform): number | undefined => {
      return getBaselineForDateRange(mp, dateSpan).baseline;
    },
    render: (mp: MonitoringPlatform) => {
      const { baseline, weather_adjusted } = getBaselineForDateRange(mp, dateSpan);
      return (
        <Chip
          icon={weather_adjusted ? 'sun' : undefined}
          label={baseline.toString()!}
          color="primary"
        />
      );
    },
    noFilter: true,
  });

  columns.push({
    header: 'Performance Ratio',
    key: 'yesterday',
    value: (mp: MonitoringPlatform) => {
      const dateRangePerf = getSolarPerformanceForDateRange(mp, dateSpan);
      // Convert to percentage with 1 decimals
      return Math.round(dateRangePerf * 1000) / 10;
    },
    render: (mp: MonitoringPlatform) => (
      <SolarPerformanceRatioBar
        monitoringPlatformId={mp.id}
        dateSpan={dateSpan}
        clickBar={() => {
          setFocusMp(mp);
          performanceOpenDialog();
        }}
      />
    ),
    width: '30%',
    noFilter: true,
    optionalHeaderElement: (
      <DateSpanPicker
        disabled={false}
        datesDidChange={datesDidChange}
        defaultStartDate={start}
        defaultEndDate={end}
      />
    ),
  });
  columns.push({
    header: 'Issues',
    key: 'issues',
    render: (mp: MonitoringPlatform) => {
      let sg = mp.SolarGenerator;
      if (sg)
        return (
          <KnownIssueButton
            device={sg}
            onClick={() => {
              setFocusMp(mp);
              issueOpenDialog();
            }}
          />
        );
      return '';
    },
    value: (mp: MonitoringPlatform) => {
      let sg = mp.SolarGenerator;
      if (!sg?.known_issues.length) return 0;
      const issues = _.sortBy(sg.known_issues, 'severity').reverse();
      return issues[0].severity;
    },
    noFilter: true,
  });

  return (
    <>
      <SortingTable
        data={monitors}
        columns={columns}
        total={monitoring_platforms?.count || 0}
        name={'SolarPerformance'}
        downloadable={true}
        allowSearchParams={true}
      />
      <SolarPerformanceGraph
        monitoringPlatformId={focusMp && focusMp.id}
        dialogOpen={performanceDialogOpen}
        closeDialog={performanceCloseDialog}
      />
      <KnownIssueForm
        device={focusMp?.SolarGenerator}
        dialogOpen={issueDialogOpen}
        closeDialog={issueCloseDialog}
      />
    </>
  );
};

export const DynamicStorageYesterdayTable: React.FC<DynamicYesterdayTableProps> = () => {
  let [focusMp, setFocusMp] = useState<Maybe<MonitoringPlatform>>();
  const [performanceDialogOpen, performanceOpenDialog, performanceCloseDialog] = useDialogState();
  const [issueDialogOpen, issueOpenDialog, issueCloseDialog] = useDialogState();
  const [start, end] = [defaultStartDate(), defaultEndDate()];
  const [dateSpan, setDateSpan] = useState<DateMillisTuple>([start.toMillis(), end.toMillis()]);

  const datesDidChange = useCallback((start_date: DateTime, end_date: DateTime) => {
    setDateSpan([start_date.toMillis(), end_date.toMillis()]);
  }, []);

  const { monitoring_platforms, loading } = useMonitoringPlatforms({
    include: [
      'storage.service_drop.site.org.*',
      'storage.service_drop.site.org.account_owner.*',
      'yesterday',
      'past_year_performance',
      'access_link',
    ],
    filter: {
      // filter out hidden and disabled storage monitors.
      'hidden': { operation: 'equals', value: false },
      'disabled': { operation: 'equals', value: false },
      'storage.service_drop.site.org.is_ams_customer': { operation: 'equals', value: true },
    },
    limit: 100,
    offset: 0,
  });

  const monitorIds =
    monitoring_platforms &&
    _.filter(Object.values(monitoring_platforms.results), { solar_generator: null }).map(
      (mp) => (mp as MonitoringPlatform)?.id
    );
  const monitors = _.truthy(monitorIds?.map(MonitoringPlatform.fromStore) || []);

  if (loading && !monitors.length) return <Loading />;

  let columns = getCommonColumns('Storage');

  columns.push({
    header: 'Performance Ratio',
    key: 'yesterday',
    value: (mp: MonitoringPlatform) => {
      const dateRangePerf = getStoragePerformanceForDateRange(mp, dateSpan);
      // Convert to percentage with 1 decimals.
      return Math.round(dateRangePerf * 1000) / 10;
    },
    render: (mp: MonitoringPlatform) => (
      <BatteryPerformanceRatioBar
        monitoringPlatformId={mp.id}
        dateSpan={dateSpan}
        clickBar={() => {
          setFocusMp(mp);
          performanceOpenDialog();
        }}
      />
    ),
    width: '30%',
    noFilter: true,
    optionalHeaderElement: (
      <DateSpanPicker
        disabled={false}
        datesDidChange={datesDidChange}
        defaultStartDate={start}
        defaultEndDate={end}
      />
    ),
  });
  columns.push({
    header: 'Issues',
    key: 'issues',
    render: (mp: MonitoringPlatform) => {
      let storage = mp.Storage;
      return (
        <KnownIssueButton
          device={storage}
          onClick={() => {
            setFocusMp(mp);
            issueOpenDialog();
          }}
        />
      );
    },
    value: (mp: MonitoringPlatform) => {
      let storage = mp.Storage;
      if (!storage?.known_issues.length) return 0;
      const issues = _.sortBy(storage.known_issues, 'severity').reverse();
      return issues[0].severity;
    },
    noFilter: true,
  });

  return (
    <div>
      <SortingTable
        data={monitors}
        columns={columns}
        total={monitoring_platforms?.count || 0}
        name={'StoragePerformance'}
        downloadable={true}
        allowSearchParams={true}
      />
      <StoragePerformanceGraph
        monitoringPlatformId={focusMp?.id}
        dialogOpen={performanceDialogOpen}
        closeDialog={performanceCloseDialog}
      />
      <KnownIssueForm
        device={focusMp?.Storage}
        dialogOpen={issueDialogOpen}
        closeDialog={issueCloseDialog}
      />
    </div>
  );
};
